<template>
  <v-form v-bind="$attrs" class="address-form" ref="addressForm">
    <mem-address-field
      light
      :color="textFieldColor"
      class="form-field"
      v-model.trim="address.address"
      :label="$t('street_address')"
      :rules="[(v) => !!v || $t('field_required')]"
      data-testid="street-address"
      @on-place-change="fillAddressForm"
    />
    <v-text-field
      light
      :color="textFieldColor"
      class="form-field"
      v-model.trim="address.address2"
      :label="$t('address_line_2')"
      data-testid="address-line-2"
    ></v-text-field>
    <v-text-field
      light
      :color="textFieldColor"
      class="form-field"
      v-model.trim="address.city"
      :label="$t('city')"
      :rules="[(v) => !!v || $t('field_required')]"
      data-testid="city"
    ></v-text-field>
    <v-select
      v-if="statesList"
      light
      :color="textFieldColor"
      class="form-field form-select"
      v-model="address.state"
      :items="statesList"
      item-text="value"
      item-value="id"
      :label="$t('state')"
      append-icon="keyboard_arrow_down"
      data-testid="state"
    ></v-select>
    <v-text-field
      light
      :color="textFieldColor"
      class="form-field"
      v-model.trim="address.zip"
      :label="$t('zip')"
      :rules="[(v) => !!v || $t('field_required')]"
      data-testid="zip"
    ></v-text-field>
    <v-select
      v-model="address.country"
      light
      :color="textFieldColor"
      class="form-field form-select"
      :class="{ 'stretched-field': !statesList }"
      :items="countries"
      item-text="value"
      item-value="id"
      :label="$t('country')"
      :rules="[(v) => !!v || $t('field_required')]"
      append-icon="keyboard_arrow_down"
      data-testid="country"
    ></v-select>
  </v-form>
</template>
<script>
import { find, upperCase } from "lodash";
import { countries, states } from "@/lists";
export default {
  inheritAttrs: false,
  components: {
    "mem-address-field": () =>
      import("@/components/base/BaseAddressSearch.vue"),
  },
  props: { address: Object, validate: Boolean },
  data: () => ({
    states,
    countries,
    textFieldColor: "#8D8D8D",
  }),
  computed: {
    statesList() {
      let countryCode = upperCase(this.address.country);
      let country = find(this.states, ["name", countryCode]);

      if (!country) return;

      return country[countryCode];
    },
  },
  watch: {
    validate: {
      handler(val) {
        if (!val) return;
        this.$refs.addressForm.validate();
      },
    },
  },
  methods: {
    // billing address methods
    fillAddressForm(address) {
      console.log("Address", address);
      // address is empty
      if (!address) return;
      // address is string
      if (address && !address.formatted) return;
      // address is object
      let { state_code, city, postcode, country_code } = address;
      let ISO_COUNTRY_CODE = country_code || "";

      // address

      // address1
      // this.address.address = formatted;
      // address2
      // country_code
      this.address.country = ISO_COUNTRY_CODE;
      // state
      if (ISO_COUNTRY_CODE === "AU" && state_code) {
        this.address.state = `AU-${state_code}`;
      } else {
        this.address.state = state_code || "";
      }
      // city
      this.address.city = city || "";
      // zip
      this.address.zip = postcode || "";

      // this.formButtons.showAddress = true;
    },
  },
};
</script>
<style lang="scss" scoped>
$mobile-view: 1024px;
.address-form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px 24px;
  @media screen and (max-width: $mobile-view) {
    grid-template-columns: 1fr;
  }
  .stretched-field {
    grid-row: 3/4;
    grid-column: 1/3;
    @media screen and (max-width: $mobile-view) {
      grid-row: auto;
      grid-column: auto;
    }
  }
  .form-field {
    &.form-select {
      ::v-deep(.v-select__selection--comma) {
        margin-top: 6px;
        margin-bottom: 6px;
      }
    }
    ::v-deep(.v-label) {
      text-transform: capitalize;
      font-weight: 500;
      color: #8d8d8d;
    }
  }
}
</style>